export const fr ={
    action:{
        accept:"J'accepte",
        postponi:"Plus tard",
        declina:"Déclin",
        cancel:"Annuler",
        download:"Télécharger",
        app:"Aller sur l'appli",
        salta:"Sauter",
        close:"Close",
        riprova:"Réessayer"
    },
    onboard:{
        lang:{
            title:"Tu choisis",
            text:"dans quelle langue préférez-vous écouter le contenu ?"
        },
        percorsi:{
            title:"Select ",
            text:"wich path do you want to follow"
        },
        tag:{
            title:"Rapprocher le smartphone des SilenceTags",
            text:"Utilisez l'APP pour accéder au contenu détaillé"
        },
        qr:{
            alernative:"Ou",
            title:"Scannez",
            text:"Scannez les QR Codes que vous trouverez en cours de route"
        },


    },
    networkerror:{
		title:"Défaillance du réseau",
        text:"Impossible de télécharger du contenu, vous ne pourrez pas utiliser l'application en mode hors ligne!",
        action:"Essayer plus tard"
    },
    storageError:{
		title:"Espace disque insuffisant",
        text:"Il n'est pas possible de télécharger tout le contenu, utilisez l'application en mode en ligne !",
        action:"Ok"
    },
    update:{
		title:"Nouveau contenu",
        text:"Un nouveau contenu est disponible, veuillez le mettre à jour avant d'utiliser l'application"
    },
    scarica:{
		title:"Téléchargement de contenu",							 
        text:"L'application sera disponible sous peu, cela peut prendre un certain temps.",
        errorconn:{
            title:"Erreur de téléchargement",
            text:"Il y a eu un problème de téléchargement, veuillez vérifier votre connexion et réessayer"
        },
        alertannulla:{
            title:"Attention",
            text:"Le contenu n'a pas été téléchargé, l'application ne peut fonctionner qu'en ligne. Veuillez réessayer plus tard pour télécharger pour une meilleure expérience"
        }
    },
    main:{

        scan:"Scanner le code QR",
        start:"Scanner le SilenceTag",
        stop:"Stop SilenceTag",
        alertApp:{
            title:"Attention",
            text:"Cette fonctionnalité est disponible via l'application",
        },
		timeoutTagTitle:"ATTENTION",
		timeoutTagText:"SilenceTag NON détecté, il est peut-être désactivé, utilisez le bouton \"Scan QR Code\"",
		timeoutTagAccept:"Continuez"
    },

    menu:{
        titile:"Réglages",
        content:{
            title:"Mettre à jour le contenu"
        },
        percorsi:{
            title:"Percorsi",
            add:"Cambia percorso",
        },
        lang:{
            title:"Langues",
            subtitile:"Langues téléchargées",
            add:"Ajouter une nouvelle langue",
            select:"Available content",
            alert:"Attention : cette opération consiste à télécharger le contenu dans la langue choisie",
            it:"Italien",
            en:"Anglais",
            fr:"Français",
            de:"Allemand",
            es:"Espagnol"
        },
        help:"Aider",
        copyright:"Copyright et Privacy",
        privacy:"Privacy",
        termini:"Conditions d'utilisation",
        test:{
            title:"Test",
            inizioTest:"Démarrer les TEST",
            stopTest:"Arrêtez les TEST",
            testoIstruzioni:"Débranchez vos écouteurs, montez le volume et lancez le test",
            compatibileTest:"Votre appareil est compatible",
            nonCompatibileTest:"L'appareil n'est pas compatible",
        }
        
    },
    raccolta:{
        title:"Collection",
        subtitle:"Revivez l'expérience",
        alerttext:"Aucun contenu n'a encore été consulté. \n Revenez à la fin de la visite pour revoir le contenu trouvé en cours de route."
    }
} 